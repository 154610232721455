
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { closeSDK, initSDK } from 'Store/v2/KYC';

interface Methods {
    goBack: () => void;
}

export default Vue.extend<any, Methods, any>({
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        isKycInProgress() {
            return this.$store.state.KYC.isKycInProgress;
        },
    },
    methods: {
        async goBack() {
            await this.$store.dispatch(closeSDK());
        },
    },
    async mounted() {
        if (!this.isKycInProgress) {
            await this.$store.dispatch(initSDK());
        }
    },
    watch: {
        async isThemeDark() {
            await this.$store.dispatch(initSDK());
        },
    },
});
